// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAwgb7WLsLmKN-phKqUf7wt9QDBmnbH6ek",
    authDomain: "feamzy.firebaseapp.com",
    databaseURL: "https://feamzy.firebaseio.com",
    projectId: "feamzy",
    storageBucket: "feamzy.appspot.com",
    messagingSenderId: "468991784341",
    appId: "1:468991784341:web:13e8a861cb91220908b29c",
    measurementId: "G-314JXJYHFY"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
